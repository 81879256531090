import docsSidebar from 'data/menu/doc-links.yaml'

const createId = link => {
  const index = link.indexOf('#')
  return index >= 0 ? link.substr(index + 1) : false
}

const extendSectionList = sectionList => {
  sectionList.forEach(section => {
    if (section.items) {
      section.items.forEach((item, index) => {
        item.hash = createId(item.link)
        if (item.subitems) {
          item.subitems.forEach(subitem => {
            subitem.hash = createId(subitem.link)
            subitem.parentLink = section.items[index].link
          })
        }
      })
    }
  })

  return sectionList
}

const sectionListDocs = extendSectionList(docsSidebar).map(item => {
  return {
    ...item,
    directory: 'docs',
  }
})

export { sectionListDocs }

import React from 'react'
import Link from 'gatsby-link'
import { css, cx } from 'emotion'
import { colors, transition } from 'css/constants'

const link = css({
  position: 'relative',
  color: colors.darkGrey,
  '&&:hover': {
    textDecoration: 'none',
  },
  '&&:before': {
    content: '" "',
    display: 'block',
    position: 'absolute',
    top: '50%',
    right: '100%',
    width: 8,
    height: 8,
    transform: 'translate(-8px, -50%) scale(0)',
    background: colors.blue,
    borderRadius: 4,
    transition: transition,
  },
  '&&:hover:before': {
    transform: 'translate(-8px, -50%) scale(1)',
  },
})

const linkActive = css({
  color: colors.blue,
  '&&:before': {
    width: 200,
    transform: 'translate(-8px, -50%) scale(1)',
  },
})

const DocsLink = ({ isActive, item, section }) => (
  <Link
    className={cx(link, { [linkActive]: isActive })}
    to={`/${section.directory}${item.link}`}
  >
    {item.title}
  </Link>
)

export default DocsLink

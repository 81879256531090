import React from 'react'
import { Link } from 'gatsby'

import { css } from 'emotion'
import {
  MIN_DEFAULT_MEDIA_QUERY,
  MOBILE_MEDIA_QUERY,
} from 'typography-breakpoint-constants'
import { rhythm, scale } from 'css/typography'
import { colors } from 'css/constants'

import cvfLogo from 'images/cvf-logo.png'

const header = css({
  color: 'white',
  background: colors.darkGrey,
  padding: rhythm(1),
  [MIN_DEFAULT_MEDIA_QUERY]: {
    padding: `${rhythm(0.5)} ${rhythm(1.5)}`,
  },
})

const heading = css({
  margin: 0,
  a: {
    display: 'flex',
    alignItems: 'center',
    color: 'white',
    textDecoration: 'none',
    '&:hover, &:focus': {
      color: '#FFFFFF',
      textDecoration: 'none',
    },
  },
  img: {
    height: 75,
    marginBottom: 0,
  },
  [MOBILE_MEDIA_QUERY]: {
    ...scale(3 / 5),
  },
})

const Header = ({ siteTitle }) => (
  <div className={header}>
    <h1 className={heading}>
      <Link to="/">
        <img src={cvfLogo} alt="ClearView Flex Logo" />
        {siteTitle}
      </Link>
    </h1>
  </div>
)

export default Header

import Typography from 'typography'
import CodePlugin from 'typography-plugin-code'
import { colors } from 'css/constants'

const typographyOptions = {
  baseFontSize: '16px',
  baseLineHeight: 1.66,
  scaleRatio: 2.5,
  googleFonts: [
    {
      name: 'Lato',
      styles: ['regular', 'bold'],
    },
    {
      name: 'Oswald',
      styles: ['300', '400'],
    },
  ],
  headerFontFamily: ['Oswald', 'sans-serif'],
  headerWeight: 300,
  bodyFontFamily: ['Lato', 'sans-serif'],
  overrideStyles: ({ scale, rhythm }, options) => ({
    'h1 a, h2 a, h3 a, h4 a, h5 a, h6 a': {
      fontWeight: options.headerWeight,
    },
    a: {
      fontWeight: 400,
      color: colors.blue,
      textDecoration: 'none',
    },
    'a:hover': {
      color: colors.darkBlue,
      textDecoration: 'underline',
    },
  }),
  plugins: [new CodePlugin()],
}

const typography = new Typography(typographyOptions)

export const { scale, rhythm, options } = typography
export default typography

const constants = {
  baseSpacing: 24,
  colors: {
    red: '#ED1B2E',
    lightGrey: '#EEEEED',
    midGrey: '#CCCCCB',
    darkGrey: '#303B3F',
    darkerGrey: '#22292C',
    blue: '#458CCF',
    lightBlue: '#609CD6',
    darkBlue: '#24496C',
    yellow: '#FC6',
    lightGreen: '#8CC75E',
    offBlack: '#111516',
  },
  radius: '4px',
  transition: '0.33s',
}

export const { baseSpacing, colors, radius, transition } = constants

export default constants

import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { graphql, StaticQuery, withPrefix } from 'gatsby'
import { colors } from 'css/constants'

import { css, cx } from 'emotion'
import { MIN_DEFAULT_MEDIA_QUERY } from 'typography-breakpoint-constants'

import Container from 'components/container'
import Header from 'components/header'
import Sidebar from 'components/sidebar/sidebar'
import 'css/base.css'

const styles = {
  appContainer: css({
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  }),
  wrapper: css({
    [MIN_DEFAULT_MEDIA_QUERY]: {
      display: 'flex',
      height: '100%',
    },
  }),
  wrapperWithSidebar: css({
    background: colors.lightGrey,
  }),
}

const Layout = ({ children, data, location, sectionList, showSidebar }) => (
  <>
    {/* prettier-ignore */}
    <Helmet>
      <title>{data.site.siteMetadata.title}</title>
      <meta name="description" content="ClearView Flex docs" />
      <meta name="keywords" content="ClearView Flex, documentation, CVF" />
      <link rel="apple-touch-icon" sizes="180x180" href={withPrefix('/apple-touch-icon.png')} />
      <link rel="icon" type="image/png" sizes="32x32" href={withPrefix('/favicon-32x32.png')} />
      <link rel="icon" type="image/png" sizes="16x16" href={withPrefix('/favicon-16x16.png')} />
      <link rel="manifest" href={withPrefix('/site.webmanifest')} />
      <link rel="mask-icon" color="#ed1b2e" href={withPrefix('/safari-pinned-tab.svg')} />
      <link rel="shortcut icon" href={withPrefix('/favicon.ico')} />
      <meta name="msapplication-TileColor" content={colors.red} />
      <meta name="msapplication-config" content={withPrefix('/browserconfig.xml')} />
      <meta name="theme-color" content="#ffffff" />
    </Helmet>
    <div className={styles.appContainer}>
      <Header siteTitle={data.site.siteMetadata.title} />
      <div
        className={cx(styles.wrapper, {
          [styles.wrapperWithSidebar]: showSidebar,
        })}
      >
        {showSidebar && (
          <Sidebar location={location} sectionList={sectionList} />
        )}
        <Container>{children}</Container>
      </div>
    </div>
  </>
)

export default props => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => <Layout data={data} {...props} />}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

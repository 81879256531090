import React from 'react'

import { css } from 'emotion'
import { rhythm } from 'css/typography'
import {
  TABLET_WIDTH,
  MIN_DEFAULT_MEDIA_QUERY,
} from 'typography-breakpoint-constants'

const container = css({
  overflow: 'auto',
  flexGrow: 1,
  padding: rhythm(1),
  [MIN_DEFAULT_MEDIA_QUERY]: {
    height: '100%',
    padding: `${rhythm(1.5)} ${rhythm(2.5)}`,
  },
})

const inner = css({
  maxWidth: TABLET_WIDTH,
  margin: 'auto',
})

const Container = ({ children }) => (
  <div className={container}>
    <div className={inner}>{children}</div>
  </div>
)

export default Container

import React from 'react'

import { css } from 'emotion'
import { MIN_DEFAULT_MEDIA_QUERY } from 'typography-breakpoint-constants'

import Section from 'components/sidebar/section'
import { rhythm } from 'css/typography'

const sidebar = css({
  flexBasis: 250,
  flexShrink: 0,
  padding: rhythm(1),
  background: '#FFFFFF',
  overflow: 'scroll',
  [MIN_DEFAULT_MEDIA_QUERY]: {
    padding: `${rhythm(1)} ${rhythm(1.5)}`,
  },
})

const SideBar = ({ location, sectionList }) => {
  if (!sectionList) {
    return null
  }

  return (
    <nav className={sidebar}>
      {sectionList.map((section, index) => (
        <Section
          key={index}
          location={location}
          name={section.title}
          section={section}
        />
      ))}
    </nav>
  )
}

export default SideBar

import React from 'react'
import { css } from 'emotion'
import { rhythm } from 'css/typography'
import getActiveItem from 'utils/sidebar/get-active-item'
import DocsLink from 'components/sidebar/docs-link'

const sectionWrapper = css({
  marginBottom: rhythm(1),
  '&:last-child': {
    marginBottom: 0,
  },
})

const sectionList = css({
  listStyle: 'none',
  padding: 0,
  margin: 0,
  li: {
    margin: 0,
  },
})

const subsectionList = css(sectionList, {
  li: {
    marginLeft: rhythm(0.5),
  },
})

const sectionTitle = css({
  marginBottom: rhythm(0.5),
})

const Section = ({ location, section }) => {
  if (!section) {
    return null
  }

  return (
    <section className={sectionWrapper}>
      <h3 className={sectionTitle}>{section.title}</h3>
      <ul className={sectionList}>
        {section.items.map(item => {
          const activeItemId = getActiveItem(
            section,
            location,
            item.hash || 'NONE'
          )
          return (
            <li key={item.link}>
              <DocsLink
                isActive={item.link === activeItemId}
                item={item}
                section={section}
              />
              {item.subitems && (
                <ul className={subsectionList}>
                  {item.subitems.map(subitem => {
                    return (
                      <li key={subitem.link}>
                        <DocsLink
                          isActive={subitem.link === activeItemId}
                          item={subitem}
                          section={section}
                        />
                      </li>
                    )
                  })}
                </ul>
              )}
            </li>
          )
        })}
      </ul>
    </section>
  )
}

export default Section
